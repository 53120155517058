import { useContext } from "react"
import { ThemeContext } from "../App"

export function OrderComp(props) {
    const { orders } = props
    const theme = useContext(ThemeContext)
    return (
        <div class="side-bar-comp">
            <p class="bold-text">Ranglijst:</p>
            <div class="column">
                {

                    orders.map((order, index) => {
                        return (
                            <div class="row">
                                <p class="bold-text">{index+1}.{order.Name}:</p>
                                <p class={`rang-comp win-comp ${theme.winColor}`}>{order.score.win}W</p>
                                <p class={`rang-comp tie-comp ${theme.secondColor}`}>{order.score.tie}G</p> 
                                <p class={`rang-comp lose-comp ${theme.loseColor}`}>{order.score.lose}V</p></div>
                        )
                    })
                }
            </div>
        </div>
    )
}
export function Opponents(props) {
    const { opponents } = props
    const theme = useContext(ThemeContext)
    return (
        <div class="side-bar-comp">
            <p class="bold-text">Tegenstanders in groep:</p>
            <div class="row">
                {opponents.map((order, index) => {
                    return (
                        
                        <p key={index} class={`opponent pop-animation ${theme.secondColor}`}>{`${order}`}</p>
                  
                    )
                })}
            </div>
        </div>

    )
}
export function CurrentOpponent(props) {
    const { opponent } = props
    return (
        <div class="side-bar-comp">

            <p class="bold-text">Huidige tegenstander:</p>
            <p>{opponent}</p>


        </div>

    )
}
export function NowOnline(props) {
    const { online } = props
    return (
        <div class="side-bar-comp">

            <div class="row"><p class="bold-text greencircle-text">Nu online</p><div class="green-circle"></div></div>
            <p>{online.Total} Gebruiker{(online.Total != 1)&&"s"}</p>


        </div>

    )
}


export function ShareGroup(props) {
    const theme = useContext(ThemeContext)
    const sharing = () => {
        navigator.clipboard.writeText(window.location.href)
        .then(() => console.log("Copied"))
        if (navigator.share) {
            navigator.share({
                title: 'Steen Papier Schaar?',
                text: `Durf jij steen papier schaar tegen mij te spelen?`,
                url: window.location.href,
            })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing', error));
        }
    }
    return (
        <button  onClick={() => sharing()} class={`share-button ${theme.secondColor}`}>
            Deel om tegen andere te spelen


        </button>

    )
}