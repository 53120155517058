import { useEffect, useState } from "react";
import { SideBar } from "../comps/sideBar";
import { CurrentOpponent, NowOnline, Opponents, OrderComp, ShareGroup } from "../comps/sidebarcomps";
import { ThemeComp } from "../comps/themeComp";
import { socket } from '../socket.js'

export function SideBarView(props) {
    const { changeTheme, game,user,active,close,reset} = props
    const [online, setOnline] = useState({ "opponents": [] })
    const [score, setScore] = useState([])
    const [isConnected, setConnectedNow] = useState(false)
    useEffect(() => {
        
        if (game) {
            
            socket.emit('join', joinRoom())
            socket.on("connect", () => {
                if (!isConnected) {
                    setConnectedNow(true)
                }

            });
            socket.on('activescore', (value) => {
                setScore(value.data.reverse())
            })  
            socket.on('onlineUsers', (value) => {
                setOnline(value)
            })


            socket.on('startgameuser' , (data) => {
             const newOnline = {...online}
             newOnline["Opponent"] = data["Opponent"]
            })
            socket.on('connect_error', (err) => {
                console.error('Connection Error:', err);
            });
            socket.on('connect_timeout', () => {
                console.error('Connection Timeout');
            });


            setConnectedNow(true)
        }
        return () => {
            socket.off('connect', setConnectedNow(true));
            socket.off('disconnect', setConnectedNow(false));
            socket.off('connect_error');
            socket.off('connect_timeout');
            socket.off('onlineUsers');
        }
    }, [game])

    useEffect(() => {
        setOnline({ "opponents": [] })
        setScore([])
    },[reset])
    function joinRoom() {
        const url = search('room')
        return { "room": url, username: user }
    }

    const search = (query) => {
        const searchURL = window.location.search
        const url = new URLSearchParams(searchURL)
        return url.get(query)
    }
    return (
        <SideBar
            active={active}
            close={() => close()}
            sidefunctions={
                <>
                <div class="top-sidebar">
                    <OrderComp orders={score} />
                    <Opponents
                        opponents={online.opponents}
                    />

                    <NowOnline
                        online={online}
                    />
                    <ThemeComp
                        changeTheme={(value) => changeTheme(value)}
                    />
                  </div>
                <div class="bottom-sidebar">
                        <ShareGroup/>
                </div>
                    
              </>
            }

        />
    )
}