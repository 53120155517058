
import { createContext, useEffect, useState } from 'react';
import './App.css';
import { SideBar } from './comps/sideBar';
import { GameView } from './views/gameView';
import { SideBarView } from './views/sideBarView';
import { socket } from './socket';


export const ThemeContext = createContext('blue-theme')
function App() {
  const THEMES = [{'mainColor':'blue-theme','secondColor':"dark-blue", mainText:'blue-text', winColor:'light-blue', loseColor:"dark-dark-blue" }, {'mainColor':'purple-theme','secondColor':"dark-purple", mainText:'purple-text', winColor:'light-purple', loseColor:"dark-dark-purple"  }, {'mainColor':'orange-theme','secondColor':"dark-orange", mainText:'orange-text' , winColor:'light-orange', loseColor:"dark-dark-orange" },{'mainColor':'green-theme','secondColor':"dark-green", mainText:'green-text', winColor:'light-green', loseColor:"dark-dark-green"  }]
  const [activeTheme, setActiveTheme] = useState(0)
  const [user,setUser] = useState('')
  const [game, setGame] = useState(false)
  const [sidebar,setSideBar] = useState(false)
  const [reset,setReset] = useState(false)
  const activateName = (name) => {
      setUser(name)
  }
  const activateGame = (game) => {
    setGame(true)
  }

  const activateSideBar = () => {
    setSideBar(!sidebar)
  }
  useEffect(() => {
    socket.on('my response',  (data) => {
        if(data.data == "Connected"){
          setReset(!reset)
          setGame(false)
        }
    }) 
  },[])

  return (
    <div className="App">
    <ThemeContext.Provider value={THEMES[activeTheme]}>
      <SideBarView
        changeTheme={(value) => setActiveTheme(value)}
        game={game}
        user={user}
        active={sidebar}
        close={() => activateSideBar()}
      />
      <GameView
        user={user}
        click={() => activateGame()}
        changeName={(name) => activateName(name)}
        game={game}
      
      />
      <button class={`sidebar-activator ${THEMES[activeTheme].mainColor} bold-text`} style={{color:"white"}} onClick={() => activateSideBar()}>Open menu en deel</button>
    </ThemeContext.Provider>
    </div>
    
  );
}

export default App;
