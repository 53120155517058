import { useContext } from "react"
import { ThemeContext } from "../App"

export function BackGroundView(){
    const themecolor = useContext(ThemeContext)
    return(
        <>
        <div class="background-view">
            <div class={`background-row ${themecolor.mainColor}`}>
            </div>
            <div class={`background-row ${themecolor.row2}`}>
            </div>
            <div class={`background-row ${themecolor.mainColor}`}>
            </div>
            <div class={`background-row ${themecolor.row2}`}>
            </div>
            <div class={`background-row ${themecolor.mainColor}`}>
            </div>
            <div class={`background-row ${themecolor.row2}`}>
            </div>
            <div class={`background-row ${themecolor.mainColor}`}>
            </div>
        </div>
        <div class="radial-view"></div>
        </>
    )
}