import { useContext } from "react"
import { ThemeContext } from "../App"

export function NextOpponent(props) {
    const { opponents, choose } = props
    const theme = useContext(ThemeContext)
    return (
        <div class="next-op-comp">
            <p class={`bold-text ${theme.mainText} center-text`}>Kies een tegenstander</p>
        <div class="row opponent-row">
            {
                opponents.map((opponent,index) => {
                    return (
                        <div key={index} onClick={() => choose(opponent)} class={`next-opponent ${theme.mainColor} pop-animation`}>
                            <p class="bold-text next-comp-text">{opponent.Name}</p>
                        </div>
                    )
                })
            }
            {(opponents.length == 0) &&           
                <div  class={`next-opponent ${theme.mainColor}`}>
                            <p class="bold-text next-comp-text">Wacht op tegenstanders...</p>
                        </div> }
        </div>
        </div>



    )
}