import { useContext } from "react"
import { ThemeContext } from "../App"
import rock from '../images/rock.png'
import paper from '../images/paper.png'
import sciccor from '../images/sciccors.png'
import rockwhite from '../images/whiterock.png'
import paperwhite from '../images/whitepaper.png'
import sciccorwhite from '../images/whitesciccor.png'
import confettiImg from '../images/confetti.png'

export function ChoiceComp(props){
    const {option,active,top,name,animation, win, confetti} = props
    const themecolor = useContext(ThemeContext)
    return(
        <div class={`choice-comp ${themecolor.mainColor} ${animation}`}>
            <Choice 
                name={'Steen'}
                photo={(active==1)?rockwhite:rock}
                click={() => option(1)}
                active={active == 1}
            />
            <Choice
                name={'Papier'}
                photo={(active == 2)?paperwhite:paper}
                click={() => option(2)}
                active={active == 2}
            />
            <Choice
                name={'Schaar'}
                photo={(active == 3)?sciccorwhite:sciccor}
                click={() => option(3)}
                active={active == 3}
            />
            <OpponentName top={top} name={name}/>
            <div class="win-row-comp">{win && <WinButton confetti={confetti}/>}</div>
        </div>
    )
}


function Choice({name, photo,active,click}){
    const themecolor = useContext(ThemeContext)
    return(
        <div onClick={click} class={`choice ${active&&themecolor.secondColor}`}>
            <img  src={photo} alt={name}/>
        </div>
    )
}

function OpponentName({name,top}){
    const theme = useContext(ThemeContext)
    return(
        <div class={`opponent-name ${top?'opponent-top':'opponent-bottom'}`}>
            <p class={`bold-text opponent-comp-text ${theme.mainColor} `}>{name}</p>
        </div>
    )
}

function WinButton(props){
    const {confetti} = props
    const theme = useContext(ThemeContext)
    return (
        <button onClick={confetti} class={`win-button ${theme.mainColor} pop-animation`}>
            <img class="confetti-img" src={confettiImg} alt="confetti"/>
        </button>
    )
}