import { useContext, useEffect } from "react"
import { ThemeContext } from "../App"

export function InputComp(props) {
    const { text, textChange, click } = props
    const color = useContext(ThemeContext)
    useEffect(() => {
        
    })
    return (
        <div class={`input-holder ${color.mainColor} pop-animation`}>
            <input class={`input-comp ${color.textColor}`}
                onChange={(event) => textChange(event.target.value)}
                value={text}
                placeholder={"Gebruikersnaam"}
                >
                
            </input>
            <button onClick={click} class={`add-button ${color.secondColor}`}>+</button>
        </div>
    )
}