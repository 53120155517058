import { useContext, useEffect, useState } from 'react'
import { ThemeContext } from '../App'

export function SideBar(props) {
    const { sidefunctions, active, close } = props
    const themecolor = useContext(ThemeContext)
    const [open, setOpen] = useState(false)
    useEffect(() => {
        if (active && !open) {
            setTimeout(() => {
                setOpen(!open)
            }, 1)
        }
        if (open) {
            setTimeout(() => {
                setOpen(!open)
            }, (500));
        }
    }, [active])
    return (

        <div class={`side-bar ${themecolor.mainColor} ${(active || open) && 'undo-display'} ${(active && open) && 'side-bar-active'}`}>
            <button onClick={close} class={`close-button ${themecolor.mainColor}`}>Ga terug</button>
            {sidefunctions}
        </div>
    )
}