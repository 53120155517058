import { useContext } from "react"
import { ThemeContext } from "../App"

export function ThemeComp(props) {
    const { changeTheme } = props
    const THEMES = ['blue-theme', 'purple-theme', 'orange-theme', 'green-theme']
    const colorContext = useContext(ThemeContext)
    return (
        <div class="side-bar-comp">
            <p class="bold-text">Personaliseer:</p>
            <p>Kies thema</p>
            <div class="row theme-row">
                <ColorButton
                    changeTheme={() => changeTheme(0)}
                    active={(colorContext.mainColor == THEMES[0])?'active-button':''}
                    color={THEMES[0]}
                />
                <ColorButton
                    changeTheme={() => changeTheme(1)}
                    active={(colorContext.mainColor == THEMES[1])?'active-button':''}
                    color={THEMES[1]}
                />
                <ColorButton
                    changeTheme={() => changeTheme(2)}
                    active={(colorContext.mainColor == THEMES[2])?'active-button':''}
                    color={THEMES[2]}
                />
                <ColorButton
                    changeTheme={() => changeTheme(3)}
                    active={(colorContext.mainColor == THEMES[3])?'active-button':''}
                    color={THEMES[3]}
                />
            </div>
        </div>
    )
}


function ColorButton(props) {
    const { color, changeTheme, active } = props
    return (
        <button onClick={changeTheme} class={`color-button ${active} ${color}`}>

        </button>
    )
}