import { useContext, useEffect, useState } from "react";
import { ChoiceComp } from "../comps/choiceComp";
import { BackGroundView } from "./backgroundView";
import { ThemeContext } from "../App";
import { InputComp } from "../comps/inputComp";
import { socket } from '../socket.js'
import { NextOpponent } from "../comps/nextOpponent.js";
import ConfettiExplosion from 'react-confetti-explosion';
export function GameView(props) {
    const [activeChoice, setActiveChoice] = useState(0)
    const [opponentChoice, setOpponentChoice] = useState(0)
    const [counter, setCounter] = useState(0)
    const [newInterval, setNewInterval] = useState(null)
    const themecolor = useContext(ThemeContext)
    const [winOrLose, setWinOrLose] = useState(0)
    const [nextOpponent, setNext] = useState(false)
    const [opponents, setOpponents] = useState([])
    const [updatedText, setUpdatedText] = useState('choose')
    const [opponent, setOpponent] = useState('Geen tegenstander')
    const [activeConfettiNow, setActiveConfetti] = useState([])
    const [confettiTime, setConfettiTimeOut] = useState(0)
    const { user, changeName, click, game, reset } = props

    useEffect(() => {
        socket.on('madeChoice', (data) => {
            setOpponentChoice(data.OpponentChoice)
            if (activeChoice) {
                setCounter(3)
                setUpdatedText("counter")
                const interval = setInterval(() => {
                    setCounter(counter => counter - 1)
                }, 1000)
                setNewInterval(interval)
            }
        })

        socket.on('startgameuser', (data) => {
            setOpponent(data.Opponent)
        })

        socket.on('chosen', () => {
            if (updatedText == "choose") {
                lightReset()
            } else {
                setNext(true)
            }

        })
        socket.on("stopGame", () => playerStopped())
        socket.on('sendAvailableOpponents', (data) => {
            setOpponents(data)
        })
        socket.on('receiveConfetti', () => {
            activeConfettiNoEmit()
        })
        return () => {
            socket.off('madeChoice')
        }

    }, [activeChoice])

    useEffect(() => resetGame(), [reset])
    useEffect(() => {
        if (counter == 0 && (activeChoice != 0 && opponentChoice != 0)) {
            clearInterval(newInterval)
            setUpdatedText("win")
            setWinOrLose(winOrLoseNow(activeChoice, opponentChoice))
            const winObj = {}
            winObj["winOrlos"] = winOrLoseNow(activeChoice, opponentChoice)
            socket.emit('keepscore', winObj)
            setTimeout(() => {
                resetGame()
                endGameNow()
            }, 4000)
        }

    }, [counter, newInterval])

    const endGameNow = () => {
        socket.emit("endgame")
    }

    const makeChoiceNow = (choice) => {
        if (!counter && updatedText != "choose") {
            setActiveChoice(choice)
            const data = {}
            data["OpponentChoice"] = choice
            socket.emit('sendChoice', data)
            if (opponentChoice) {
                setCounter(3)
                setUpdatedText("counter")
                const interval = setInterval(() => {
                    setCounter(counter => counter - 1)
                }, 1000)
                setNewInterval(interval)
            }
        }
    }
    const playerStopped = () => {
        setCounter(0)
        setActiveChoice(0)
        setOpponentChoice(0)
        setWinOrLose(0)
        setOpponent('Geen tegenstander')
        setUpdatedText("choose")
        setNext(false)
    }

    const resetGame = () => {
        setCounter(0)
        setActiveChoice(0)
        setOpponentChoice(0)
        setWinOrLose(0)
        setOpponent('Geen tegenstander')
        if (nextOpponent) {
            setUpdatedText("VS")
        } else {
            setUpdatedText("choose")
        }
        socket.emit('availableOpponents')
        setNext(false)
    }
    const lightReset = () => {
        setUpdatedText("VS")
        setCounter(0)
        setActiveChoice(0)
        setOpponentChoice(0)
        setWinOrLose(0)
        setNext(false)
        setOpponent('Geen tegenstander')
    }

    const chooseOpponent = (value) => {
        setNext(false)
        setUpdatedText("VS")
        socket.emit("chooseOpponent", value)
        socket.emit('startgame')
    }
    const activeConfetti = () => {
        const newConffeti = [...activeConfettiNow, 1]
        clearTimeout(confettiTime)
        if (newConffeti.length < 4) {
            setActiveConfetti(newConffeti)
        }

        const timeout = setTimeout(() => {

            setActiveConfetti([])
        }, 1500)
        setConfettiTimeOut(timeout)
        socket.emit('confetti')
    }
    const activeConfettiNoEmit = () => {
        const newConffeti = [...activeConfettiNow, 1]
        clearTimeout(confettiTime)
        if (newConffeti.length < 4) {
            setActiveConfetti(newConffeti)
        }

        const timeout = setTimeout(() => {

            setActiveConfetti([])
        }, 1500)
        setConfettiTimeOut(timeout)
    }
    return (
        <div class="game-view">
            <BackGroundView />
            <div class="background-confetti">{activeConfettiNow.map(() => {
                return (<ConfettiExplosion />)
            })}</div>
            {(user != '' && game) ?
                <>
                    <ChoiceComp
                        animation={'pop-in-top'}
                        option={() => { }}
                        active={(activeChoice && !counter) ? opponentChoice : 0}
                        top={true}
                        name={opponent}

                    />

                    <BetweenTextComp
                        showing={updatedText}
                        counter={counter}
                        win={winOrLose}
                        themecolor={themecolor}
                        opponents={opponents}
                        choose={(value) => chooseOpponent(value)}
                    />
                    <ChoiceComp
                        animation={'pop-in-bottom'}
                        name={"Maak keuze"}
                        option={(value) => makeChoiceNow(value)}
                        active={activeChoice}
                        win={(winOrLose == "Gewonnen!")}
                        confetti={() => activeConfetti()} />

                </>
                :
                <InputComp
                    click={click}
                    text={user}
                    textChange={(value) => changeName(value)}
                />
            }
        </div>


    )
}


function BetweenTextComp(props) {
    const { showing, counter, win, themecolor, opponents, choose } = props
    switch (showing) {
        case "VS": return <p class={`vs-text pop-animation-1 ${themecolor.mainText}`}>VS</p>
        case "counter": return <p class={`vs-text pop-animation-3 ${themecolor.mainText}`}>{counter}</p>
        case "win": return <p class={`vs-text win-text pop-animation-4 ${themecolor.mainText}`}>{win}</p>
        case "choose": return <NextOpponent choose={(opponent) => choose(opponent)} opponents={opponents} />
        default: return <p class={`vs-text pop-animation ${themecolor.mainText}`}>VS</p>
    }
}

function winOrLoseNow(user, opponent) {
    if ((opponent == 1 && user == 2) || (opponent == 2 && user == 3) || (opponent == 3 && user == 1)) {
        return "Gewonnen!"
    }
    else if (opponent == user) {
        return "Gelijk"
    } else {
        return "Verloren :-("
    }



}